import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/animate.css/animate.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/src/app/global.css");
;
import(/* webpackMode: "eager", webpackExports: ["PHProvider"] */ "/app/src/shared/providers/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ReduxProvider"] */ "/app/src/shared/providers/reduxProvider.tsx");
